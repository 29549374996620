@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* scroll-behavior: smooth; */
    overflow-x: hidden;
    direction: rtl;
}

:root{
    --body-text:#284232;
    --body-bg:#F8FAF9;
    --card-btns:#314D39;
}

body{
    background-color: var(--body-bg);
}
::-webkit-scrollbar {
    display: none;
}

Link, a{
    text-decoration: none;
}