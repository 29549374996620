.cart {
    position: fixed;
    z-index: 999999;
    bottom: 0;
    left: 0;
    margin-left: 15px;
    margin-bottom: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    width: 300px;
    border-radius: 20px;
    padding: 8px;
    height: auto;
  }
  .product-row{
    display: flex;
    padding: 3px 5px 5px 3px;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
  }
  .remove-product-btn{
    margin-right: auto;
    margin-left: 5px;
    border: none;
    outline: none;
    padding-top: 3px;
    background-color: transparent;
  }
.total{
  display: block;
  padding-top: 8px;
}