.food-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    width: 160px;
  }
  
  .food-card:hover {
    transform: scale(1.05) !important;
  }
  
  .food-card-img-container {
    position: relative;
    overflow: hidden;
    height: 180px;
  }
  .add-product{
    position: absolute;
    left: 0;
    padding: 8px 8px 0px;
    /* display: flex; */
    background-color: #ffffff00;
    border: none;
    width: 40px;
    height: 40px;
    z-index: 5;
  }
  
  .food-card-img-container img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  .food-card-logo-container {
    background: linear-gradient(to top, rgba(0,0,0,0.0) 0%,rgba(0,0,0,0.7) 100%);
    width: 100%;
    height: 50px;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
  }
  .persons-number{
    position: absolute;
    color: #fff;
    height: auto;
    left: 0;
    margin: 5px 0px 0px 5px;
  }
  .food-card-title-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.0) 0%,rgba(0,0,0,0.7) 100%);
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .food-card-title {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
  }
  
  .food-card-price {
    margin: 0;
    font-size: 14px;
    background-color: var(--card-btns);
    width: auto;
    padding: 1px 5px;
    border-radius: 10px;
  }
  .svgs{
    color: var(--card-btns);
  }
  .logo{
    position: absolute;
    width: 15px !important;
    height: auto !important;
    margin-right: 5px;
    margin-top: 5px;
  }

  .food-card-img-container {
    position: relative;
  }
  
  .food-card-img-container:hover img {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  
  .food-card-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .food-card-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }
  .order-notes{
    margin-top: 10px;
    padding-right: 5px;
    height: 30px;
    border-radius: 10px;
    border: none;
  }
  .card-buttons{
    margin-top: 10px;
    gap: 10px;
    display: flex;
  }
.card-buttons a{
  text-decoration: none;
  color: #fff;
  display: flex;
  gap: 5px;
}
.card-buttons a:hover{
  color: #fff;
}
  .food-card-details-text{
    color: white;
  }
  .food-card-details.show {
    opacity: 1;
  }

.zaza{
display: flex;
justify-content: center;
gap: 20px;
}

@media screen and (max-width: 1000px) {
  .food-card-img-container{
max-width: 350px;
max-height: 200px;
  }
}
@media screen and (max-width: 450px) {
  .food-card-img-container{
max-width: 170px;
max-height: 170px;
  }
  .food-card{
    width: 170px;
  }
}

@media screen and (max-width: 375px) {
  .food-card-img-container{
max-width: 155px;
max-height: 155px;
  }
  .food-card{
    width: 155px;
  }
}