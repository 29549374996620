.CategoryCard{
    width: 130px;
    height: 200px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    align-items: flex-end;
    color: #fff;
    background-size: cover;
    transition: border-radius 1s ease;
}

@media screen and (min-width: 800px) {
    .CategoryCard{
        width: 250px;
        height: 200px;     
    }
  }

@media screen and (min-width: 1100px) {
    .CategoryCard{
        width: 350px;
        height: 250px;     
    }
  }

  .CategoryCard.circle {
    border-radius: 120px;
    width: 90px;
    height: 90px;
  }