.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 105%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.modal-content {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  text-align: center;
  transition: all ease-in .4s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-desc {
  font-size: 14px;
  margin-bottom: 10px;
}

.product-price {
  background-color: var(--card-btns);
  border-radius: 10px;
  color: #ffff;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 2px;
  text-align: center;
  margin-bottom: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-page-btns {
  background-color: var(--card-btns);
  padding: 8px 14px;
  border-radius: 5px;
}

.link {
  color: var(--card-btns);
  font-weight: bold;
}

.animate-in {
  transition: all ease-out .3s;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.modal-buttons a {
  display: flex;
  align-items: center;
  display: flex;
  color: var(--body-text);
  gap: 5px;
}

.Product .modal-buttons a {
  color: #fff;
}

.Product {
  background-color: #E1DCD5 !important;
}

.modal-content button {
  background-color: #f03a3a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #801818;
}

.notIndividualPrices {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
}
.inProductPage{
  padding: 0px 12.5vw;
}


@media screen and (min-width: 993px) {
  .product-image {
    width: 40vw;
  }
  .product-price {
    width: 120px;
  }
}

@media screen and (min-width: 800px) {
  .product-image{
    width: 60vw !important;
    }
    .product-price {
      padding: 25px 0px;
    }
    .product-desc {
      /* height: 100px; */
      width: 80%;
      padding: 15px 0px;
    }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .product-image {
    width: 40vw;
  }

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .product-image {
    width: 70vw;
  }
  .product-price{
    width: 80px;
  }
}